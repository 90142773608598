body {
  margin: 0;
  padding: 0!important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.html {
  scroll-behavior: smooth;
}
.rc-pagination-next,
.rc-pagination-prev {
  background: white;
}
.rc-pagination-next:focus,
.rc-pagination-prev:focus{
  outline: none;
}
.form-control.is-invalid, .was-validated .form-control:invalid{
  border-color: #F5A623!important;
  background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23f5a623' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23f5a623' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") !important;
}
.form-control.is-invalid:focus, .was-validated .form-control:invalid:focus{
  border-color: #F5A623!important;
  box-shadow: 0 0 0 0.2rem rgba(245, 166, 35, .25)!important;
}
.invalid-feedback{
  color: #F5A623!important;
}